import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts/core';
import {
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { LineChart, PieChart } from 'echarts/charts';
import { UniversalTransition, LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { Box } from '@chakra-ui/react';

// Registrar los componentes de ECharts
echarts.use([
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  PieChart,
  CanvasRenderer,
  UniversalTransition,
  LabelLayout
]);

const Graf3bis = ({data1}) => {
  const chartRef = useRef(null); // Referencia al contenedor del gráfico
  const [datos, setDatos] = useState(data1);
  const [periodos, setPeriodos] = useState(Array.from(new Set(data1.map(item => item.fecha_liquidacion))));
  const [valorDolar, setValorDolar] = useState(Array.from(new Set(data1.map(item => item.promedio_dolar))));
  const [noEmbQ, setNomEmbQ] = useState([]);
  const [noEmbM, setNomEmbM] = useState([]);
  const [embM, setEmbM] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setDatos(data1);
  }, [data1]);

  useEffect(() => {
    //setPeriodos(Array.from(new Set(datos.map(item => item.fecha_liquidacion))))
    setPeriodos(prevPeriodos => {
      const periodosUnicos = Array.from(new Set(datos.map(item => item.fecha_liquidacion)));
      periodosUnicos.unshift('tipoEmpleado');  // Agregas el valor manual al principio
      return periodosUnicos;
    });

    setValorDolar(prevPeriodos => {
      const dolar = Array.from(new Set(datos.map(item => item.promedio_dolar.toFixed(2))));
      dolar.unshift('dolar');  // Agregas el valor manual al principio
      return dolar;
    });

    setNomEmbQ(prev => {
      const nuevo = datos
                        .filter(item => item.tipo_empleado.trim() === "Quincenal - NOEMBARC")
                        .map(item => (item.importe_ARS/item.promedio_dolar).toFixed(2));
      nuevo.unshift('NoEmbarcadosQuincenales');  // Agregas el valor manual al principio
      return nuevo;
    });

    setNomEmbM(prev => {
      const nuevo = datos
                        .filter(item => item.tipo_empleado.trim() === "Mensual - NOEMBARC")
                        .map(item => (item.importe_ARS/item.promedio_dolar).toFixed(2));
      nuevo.unshift('NoEmbarcadosMensuales');  // Agregas el valor manual al principio
      return nuevo;
    });

    setEmbM(prev => {
      const nuevo = datos
                        .filter(item => item.tipo_empleado.trim() === "Mensual - EMBARCADO")
                        .map(item => (item.importe_ARS/item.promedio_dolar).toFixed(2));
      nuevo.unshift('EmbarcadosMensuales');  // Agregas el valor manual al principio
      return nuevo;
    });

  }, [datos]);

  useEffect(() => {  
    setTotal(
      datos
          .filter(item => item.fecha_liquidacion === periodos[periodos.length - 1]) // Filtrar por fecha_liquidacion
          .reduce((acumulador, item) => acumulador + (item.importe_ARS/item.promedio_dolar), 0) // Sumar los importes
          .toFixed(2)
    );
  }, [periodos, datos]);


  useEffect(() => {
    const chartDom = chartRef.current;
    let myChart;

    if (chartDom) {
      myChart = echarts.init(chartDom);

      const option = {
        title: [
          {
            text: '$ '+ Number(total).toLocaleString(),
            subtext: 'Total Cargas sociales del Periodo ' + periodos[periodos.length - 1],
            left: '20px',
            top: "20px",
            textStyle: {
              fontSize: 50, // Tamaño de fuente del título adicional
            },
          },
          {
            text: 'Valor Dolar: $' + valorDolar[valorDolar.length - 1],
            subtext: 'Promedio del periodo ' + periodos[periodos.length - 1],
            left: '20px',
            top: "120px",
          },
        ],
        legend: {
          top: "10px",
          right: "20px"
        },
        tooltip: {
          trigger: 'axis',
          showContent: false
        },
        dataset: {
          source: [
           periodos,
           noEmbQ,
           noEmbM,
           embM
          ]
        },
        xAxis: { type: 'category' },
        yAxis: { gridIndex: 0 },
        grid: { top: '55%' },
        series: [
          {
            type: 'line',
            smooth: true,
            color: '#00a25a',
            seriesLayoutBy: 'row',
            emphasis: { focus: 'series' }
          },
          {
            type: 'line',
            smooth: true,
            color: '#23cacc',
            seriesLayoutBy: 'row',
            emphasis: { focus: 'series' }
          },
          {
            type: 'line',
            smooth: true,
            color: '#2337cc',
            seriesLayoutBy: 'row',
            emphasis: { focus: 'series' }
          },
          {
            type: 'pie',
            id: 'pie',
            color: ['#00a25a', '#23cacc', '#2337cc' ],
            radius: '30%',
            center: ['50%', '25%'],
            emphasis: {
              focus: 'self'
            },
            label: {
              formatter: `{b}: {@[${periodos.length - 1}]} ({d}%)`
            },
            encode: {
              itemName: 'tipoEmpleado',
              value: periodos[periodos.length - 1],
              tooltip: periodos[periodos.length - 1]
            }
          }
        ]
      };

      // Manejar el evento de actualización del puntero del eje
      myChart.on('updateAxisPointer', function (event) {
        const xAxisInfo = event.axesInfo[0];
        if (xAxisInfo) {
          const dimension = xAxisInfo.value + 1;
          const total2 = datos
                            .filter(item => item.fecha_liquidacion === periodos[dimension]) // Filtrar por fecha_liquidacion
                            .reduce((acumulador, item) => acumulador + (item.importe_ARS/item.promedio_dolar), 0) // Sumar los importes
                            .toFixed(2)
          myChart.setOption({
            title: [
              {
                text: '$ '+ Number(total2).toLocaleString(),
                subtext: 'Total Cargas sociales del Periodo ' + periodos[dimension],
                left: '20px',
                top: "20px",
                textStyle: {
                  fontSize: 50, // Tamaño de fuente del título adicional
                },
              },
              {
                text: 'Valor Dolar: $' + valorDolar[dimension],
                subtext: 'Promedio del periodo ' + periodos[dimension],
                left: '20px',
                top: "120px",
              },
            ],
            series: {
              id: 'pie',
              label: {
                formatter: `{b}: {@[${dimension}]} ({d}%)`
              },
              encode: {
                value: periodos[dimension],
                tooltip: periodos[dimension]
              }
            }
          });
        }
      });

      myChart.setOption(option);
    }

    // Limpiar el gráfico al desmontar el componente
    return () => {
      if (myChart) {
        myChart.dispose();
      }
    };
  }, [periodos,noEmbQ,noEmbM,embM]);

  return (
    <Box background="#f3f3f3" borderRadius='30px'>
      <Box ref={chartRef} w='auto' h='500px'/>
    </Box>
  );
};

export default Graf3bis;
