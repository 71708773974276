const ConfigRoutes = {
    home: '/',
    stats: '/stats',
    track: '/track',
    rrhh: '/rrhh',
    prod: '/prod',
    exit: '/',
  };
  
  export default ConfigRoutes;
  