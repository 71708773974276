import React, { useState, useEffect } from 'react';
import Boat from '../boat/Boat';
import LineTime from '../lineTime/LineTime';
import BoatDetails from '../boat/BoatDetails';
import BreadcrumbNav from '../nav/BreadcrumNav';
import Port from '../port/Port';
import { Flex,
  Box,
  SimpleGrid
  } from '@chakra-ui/react'

import './Track.css';

const Track = ({boats, ocultarBarra}) => {

  const [verDetalleBarco, setVerDetalleBarco] = useState(false);
  const [barcoSeleccionado, setBarcoSeleccionado] = useState({});
  
  const onClickDetailsBoat = (boat, ver) => {
    setBarcoSeleccionado(boat);
    setVerDetalleBarco(ver);
  };

  return (
    <Box overflow="hidden">
      {!ocultarBarra &&
      <Box h='10%'>
        <BreadcrumbNav seleccionado={'2'} tipoUser ={'G'} />
      </Box>
      }
      {verDetalleBarco &&
        <BoatDetails buque={barcoSeleccionado} ocultar={onClickDetailsBoat} />
      }
      <Flex>
        <Port ocultarBarra={ocultarBarra}  />
        <SimpleGrid column={1} spacing={0} w="100%" h={!ocultarBarra ? "94.1vh" : "100vh"} >
            {boats.sort((a, b) => a.orden - b.orden).map((boat, index) => (
              <Box key={boat.id} 
                    onClick= {() => onClickDetailsBoat(boat, true)}>
                <Boat key={boat.id} buque={boat} index= {index}/>
              </Box>
            ))}
        </SimpleGrid>
      </Flex>
    </Box>
  );
};

export default Track;
