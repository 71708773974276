import React, { useState, useEffect } from 'react';
import './Port.css';
import { Flex,
  Box,
  Heading 
  } from '@chakra-ui/react'


//<div className="horizontal-text">Puerto</div>
const Port = ({ocultarBarra}) => {
  //#9c9c9c
  return (
    <Box h={!ocultarBarra ? "94.1vh" : "100vh"} w='10%' display='flex' justifyContent='center' alignItems='center'
          background={`linear-gradient(45deg, transparent 33.33%, rgba(57, 144, 179, 0.1) 33.33%, rgba(0, 0, 0, 0.1) 66.66%, transparent 66.66%), #9c9c9c`}
          backgroundSize= "70px 70px"
    
    >
        <Heading 
          transform="rotate(-90deg)" // Rota el contenido 90 grados
          whiteSpace = "nowrap"
          display = "inline-block"
          borderRight="6px"
          fontSize="6em"
          letterSpacing="4px"
          fontFamily="Arial"
          color="transparent"
          position="relative"
          filter="drop-shadow(0 0 23px #19273a)"
          sx={{
            WebkitTextStroke: "4px #ebebeb", // Aplica el text stroke
          }}
        >
            PUERTO
        </Heading>
    </Box>
  );
};

export default Port;
