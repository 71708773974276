import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts/core';
import {
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { LineChart, PieChart, BarChart } from 'echarts/charts';
import { UniversalTransition, LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { Box } from '@chakra-ui/react';

// Registrar los componentes de ECharts
echarts.use([
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  PieChart,
  BarChart,
  CanvasRenderer,
  UniversalTransition,
  LabelLayout
]);

const Graf1 = ({data1}) => {
  const chartRef = useRef(null); // Referencia al contenedor del gráfico
  const [datos, setDatos] = useState(data1);
  const [periodos, setPeriodos] = useState(Array.from(new Set(data1.map(item => item.fecha_liquidacion))));
  const [valorDolar, setValorDolar] = useState(Array.from(new Set(data1.map(item => item.promedio_dolar))));
  const [noEmb, setNoEmb] = useState([]);
  const [embM, setEmbM] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setDatos(data1);
  }, [data1]);

  useEffect(() => {
    //setPeriodos(Array.from(new Set(datos.map(item => item.fecha_liquidacion))))
    setPeriodos(prevPeriodos => {
      const periodosUnicos = Array.from(new Set(datos.map(item => item.fecha_liquidacion)));
      return periodosUnicos;
    });

    setValorDolar(prevPeriodos => {
      const dolar = Array.from(new Set(datos.map(item => item.promedio_dolar.toFixed(2))));
      dolar.unshift('dolar');  // Agregas el valor manual al principio
      return dolar;
    });

    setNoEmb(prev => {
      const nuevo = datos
                        .filter(item => (item.tipo_empleado.trim() === "Mensual - NOEMBARC" || item.tipo_empleado.trim() === "Quincenal - NOEMBARC"))
                        .map(item => (item.importe_ARS/item.promedio_dolar).toFixed(2));
      nuevo.unshift('NoEmbarcados');  // Agregas el valor manual al principio
      return nuevo;
    });

    setEmbM(prev => {
      const nuevo = datos
                        .filter(item => item.tipo_empleado.trim() === "Mensual - EMBARCADO")
                        .map(item => (item.importe_ARS/item.promedio_dolar).toFixed(2));
      nuevo.unshift('Embarcados');  // Agregas el valor manual al principio
      return nuevo;
    });

  }, [datos]);

  useEffect(() => {  
    setTotal(
      datos
          .filter(item => item.fecha_liquidacion === periodos[periodos.length - 1]) // Filtrar por fecha_liquidacion
          .reduce((acumulador, item) => acumulador + (item.importe_ARS/item.promedio_dolar), 0) // Sumar los importes
          .toFixed(2)
    );
  }, [periodos, datos]);


  useEffect(() => {
    const chartDom = chartRef.current;
    let myChart;

    if (chartDom) {
      myChart = echarts.init(chartDom);

      const option = {
        legend: {},
        tooltip: {},
        dataset: {
          source: [
            ['Embarcados', 'No Embarcados'],
            embM,
            noEmb
          ]
        },
        xAxis: { 
            type: 'category',
            data: periodos

         },
        yAxis: {
            
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [{ type: 'bar' }, { type: 'bar' }]
      };

      myChart.setOption(option);
    }

    // Limpiar el gráfico al desmontar el componente
    return () => {
      if (myChart) {
        myChart.dispose();
      }
    };
  }, [periodos,noEmb,embM]);

  return (
    <Box background="#f3f3f3" borderRadius='30px'>
      <Box ref={chartRef} w='auto' h='500px'/>
    </Box>
  );
};

export default Graf1;
