import React, { useState, useEffect } from 'react';
import Bodega from '../bodega/Bodega';
import BreadcrumbNav from '../nav/BreadcrumNav';
import {
    Box,ButtonGroup,Button, Flex
  } from '@chakra-ui/react'
import './Track.css';

const Track2 = ({boats, actualizar, ocultarBarra}) => {

  const [verDetalleBarco, setVerDetalleBarco] = useState(false);
  const [barcoSeleccionado, setBarcoSeleccionado] = useState({});
  const [isFiltered, setIsFiltered] = useState(false); // Estado para controlar el filtro
  
  const toggleFilter = () => {
    setIsFiltered(!isFiltered);
  };
  
  const onClickDetailsBoat = (boat, ver) => {
    setBarcoSeleccionado(boat);
    setVerDetalleBarco(ver);
  };

  return (
    <Box className="track" background='#f3f3f3'>
      {!ocultarBarra &&
      <Box h='10%'>
        <BreadcrumbNav seleccionado={'1'} tipoUser ={'G'} />
      </Box>
      }
      <Box position="relative" w="100%"> {/* Contenedor que tendrá la posición relativa */}
        <ButtonGroup spacing='2' position="absolute" top="3" right="6" p="2" background="#f3f3f3" zIndex="3"> {/* Botón posicionado */}
          <Button variant='solid' colorScheme='teal' onClick={toggleFilter}>
            {isFiltered ? 'Todos los Buques' : 'Buques Navegando'}
          </Button>
        </ButtonGroup>
      </Box>
      <Box overflowY='auto'>
        {boats.filter(boat => isFiltered || boat.estado === 0).sort((a, b) => a.estado - b.estado).map((boat, index) => (
          <Box key={boat.id}  onClick= {() => onClickDetailsBoat(boat, true)}>
            <Bodega className='track-container' key={boat.id} buque={boat} index= {index} actualizar = {actualizar}/>
          </Box>
        ))}
      </Box>
      
    </Box>
  );
};



export default Track2;
