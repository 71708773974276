import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Box, Button, Flex, Divider } from '@chakra-ui/react';
import { useAuth } from '../authentication/AuthProvider';
import ConfigRoutes from '../../config/ConfigRoutes';

import { HamburgerIcon, ViewIcon, LockIcon, StarIcon } from '@chakra-ui/icons'

const BreadcrumbNav = ({seleccionado, tipoUser}) => {
    const { logout } = useAuth();
  //#f3f3f3
    return (
        <Box background='#f3f3f3' p='10px' h="100%" w= "100%" boxShadow='inner' >
            <Breadcrumb  fontSize='2xl' ml='30px'>
            {tipoUser === 'G' &&
                <BreadcrumbItem cursor='pointer' >
                    <BreadcrumbLink as={Link} to={ConfigRoutes.stats} textDecoration={seleccionado === '1' ? 'underline' : 'none'} transition="all 0.2s ease-in-out" marginRight="20px"
                    _hover={{
                        transform: 'translateY(-2px)',
                    }}>
                        <HamburgerIcon marginRight="10px" />
                        Estadísticas
                    </BreadcrumbLink>
                </BreadcrumbItem>
            }
            {tipoUser === 'G' &&
                <BreadcrumbItem cursor='pointer'>
                    <BreadcrumbLink as={Link} to={ConfigRoutes.track} textDecoration={seleccionado === '2' ? 'underline' : 'none'} transition="all 0.2s ease-in-out" marginLeft="10px" marginRight="20px"
                    _hover={{
                        transform: 'translateY(-2px)',
                    }}>
                        <ViewIcon marginRight="10px" />
                        Estado de Buques
                    </BreadcrumbLink>
                </BreadcrumbItem>
            }
            {tipoUser === 'R' &&
                <BreadcrumbItem cursor='pointer' >
                    <BreadcrumbLink as={Link} to='/' textDecoration={seleccionado === '1' ? 'underline' : 'none'} transition="all 0.2s ease-in-out" marginRight="20px"
                    _hover={{
                        transform: 'translateY(-2px)',
                    }}>
                        <HamburgerIcon marginRight="10px" />
                        Estadísticas
                    </BreadcrumbLink>
                </BreadcrumbItem>
            }
            {tipoUser === 'R' &&
                <BreadcrumbItem cursor='pointer'>
                    <BreadcrumbLink as={Link} to={ConfigRoutes.prod} textDecoration={seleccionado === '3' ? 'underline' : 'none'} transition="all 0.2s ease-in-out" marginLeft="10px"
                    _hover={{
                        transform: 'translateY(-2px)',
                    }}
                    >
                        <StarIcon marginRight="10px"  />
                        Produccion
                    </BreadcrumbLink>
                </BreadcrumbItem>
            }

                <BreadcrumbItem cursor='pointer'>
                    <BreadcrumbLink as={Link} to={ConfigRoutes.exit} color='red' textDecoration={seleccionado === '4' ? 'underline' : 'none'} transition="all 0.2s ease-in-out" marginLeft="10px"
                    _hover={{
                        transform: 'translateY(-2px)',
                    }}
                    onClick={() => logout()}
                    >
                        <LockIcon marginRight="10px"  />
                        Cerrar Sesión
                    </BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
        </Box>
    );
  }

  
export default BreadcrumbNav;